/**
 * Created by osirvent on 25/04/2017.
 */
angular
    .module('annexaApp')
    .component('annexaDocumentInformationBox', {
        templateUrl: './components/doc/annexa-document-information-box/annexa-document-information-box.html',
        require: {
            documentComponent: '^^annexaDocument'
        },
        controller: ['Language', 'DccumentsFactory', 'GlobalDataFactory', 'AnnexaFormlyFactory', 'DccumentsFactory', 'DialogsFactory', 'CustomFieldFactory', '$state', '$filter', '$scope', function (Language, DccumentsFactory, GlobalDataFactory, AnnexaFormlyFactory, DccumentsFactory, DialogsFactory, CustomFieldFactory, $state, $filter, $scope) {
            var vm = this;
			vm.canChangeDocumentType = false;
            vm.languageColumn = Language.getActiveColumn();
			vm.docTypes = [];
            vm.updateCustomField = function (field) {
                var model = angular.copy(field);
                model.document = { id: vm.documentComponent.document.id };
                model.customField = { id: field.customField.id };

                DccumentsFactory.updateDocumentCustomField(model)
                    .then(function(dataUPDATE) {
                        if(field.customField.frontendType == 'SELECT') {
                        	$scope.$broadcast('customFieldSelectSelected', { customField: field.customField.id, selectedValue: field.id });
                        }
                        if(dataUPDATE){
                        	if(dataUPDATE.showCFs){
								var keys = Object.keys(dataUPDATE.showCFs);
	                            _.forEach(keys, function(key){
	                            	var indexCFk = $linq(vm.documentComponent.document.customFields).indexOf("x => x.id == "+key );
	                            	if(indexCFk > -1){
	                            		if(dataUPDATE.showCFs[key]){
	                            			vm.documentComponent.document.customFields[indexCFk].invisible = true;
	                            			try{
	                            				if(vm.documentComponent.document.customFields[indexCFk] && vm.documentComponent.document.customFields[indexCFk].value && vm.documentComponent.document.customFields[indexCFk].value !== ''){
	                            					var valueAux = angular.copy(vm.documentComponent.document.customFields[indexCFk].value);
	                            					valueAux = JSON.parse(valueAux);
	                            					valueAux.value = '';
	                            					vm.documentComponent.document.customFields[indexCFk].value = JSON.stringify(valueAux);
	                            				}
	                            				if (vm.documentComponent.document.customFields[indexCFk]){
	                            					if(vm.documentComponent.document.customFields[indexCFk].customField && (vm.documentComponent.document.customFields[indexCFk].customField.frontendType == 'MULTIPLESELECT' || vm.documentComponent.document.customFields[indexCFk].customField.frontendType == 'CHECKBOX')) {
	                            						vm.documentComponent.document.customFields[indexCFk].valueFromJSON = [];
	                            					} else {
	                            						vm.documentComponent.document.customFields[indexCFk].valueFromJSON = '';
	                            					}
	                            				}
	                            			}catch(e){}
		            					}else{
		            						vm.documentComponent.document.customFields[indexCFk].invisible = false;
		            					}
	                            	}
	                            });
							}
							if(dataUPDATE.showCFGs){
								var deleteGCFFromGroup = function(groups, key, show){
									var deleted = false;
									if(groups){
										var indexCFk = $linq(groups).indexOf("x => x.id == "+key );
										if(indexCFk > -1){
											if(show){
												groups[indexCFk].invisible = true;
												groups[indexCFk].value = '';
											}else{
												groups[indexCFk].invisible = false;
											}
											deleted = true;
										}else{
											_.forEach(groups, function(cfAux){
												if(!deleted && cfAux.relatedCustomField && cfAux.relatedCustomField.frontendType === 'CF_GROUP'){
													deleted = deleteGCFFromGroup(cfAux.groups, key, show);
												}
											});
										}
									}
									return deleted;
								}
								var keys = Object.keys(dataUPDATE.showCFGs);
	                            _.forEach(keys, function(key){
	                           		var deleted = false;
	           						_.forEach(vm.documentComponent.document.customFields, function(cfAux){
	           							if(!deleted && cfAux.customField && cfAux.customField.frontendType === 'CF_GROUP'){
	           								deleted = deleteGCFFromGroup(cfAux.groups, key, dataUPDATE.showCFGs[key]);
	           							}
	           						});
	                            });
							}
	                        if(dataUPDATE.calculatedCFs && vm.documentComponent.document.customFields){
								var keys = Object.keys(dataUPDATE.calculatedCFs);
	                            _.forEach(keys, function(key){
	                            	var cfToChange = $linq(vm.documentComponent.document.customFields).firstOrDefault(undefined, "x => x.id == "+key );
	            					if(cfToChange){
	            						cfToChange.value = dataUPDATE.calculatedCFs[key];
	            						CustomFieldFactory.calculateValueJSONCustomField(cfToChange);
	            					}
	                            });
							}
							if(dataUPDATE.calculatedCFGs){
								var calculateGCFFromGroup = function(groups, key, calculateds){
									var calc = false;
									if(groups){
										var cFk = $linq(groups).firstOrDefault(undefined, "x => x.id == "+key );
										if(cFk){
											cFk.value = calculateds[key];
											CustomFieldFactory.calculateValueJSONGroupCustomField(cFk);
											calc = true;
										}else{
											_.forEach(groups, function(cfAux){
												if(!calc && cfAux.relatedCustomField && cfAux.relatedCustomField.frontendType === 'CF_GROUP'){
													calc = calculateGCFFromGroup(cfAux.groups, key, calculateds);
												}
											});
										}
									}
									return calc;
								}
								var keys = Object.keys(dataUPDATE.calculatedCFGs);
	                            _.forEach(keys, function(key){
	    	 						var calc = false;
	        						_.forEach(vm.documentComponent.document.customFields, function(cfAux){
	        							if(!calc && cfAux.customField && cfAux.customField.frontendType === 'CF_GROUP'){
	        								calc = calculateGCFFromGroup(cfAux.groups, key, dataUPDATE.calculatedCFGs);
	        							}
	        						});
	                        	});
							}
                        }
                    }).catch(function(error) {
                        //Empty
                });
            };

			vm.openModalUpdateDocumentType = function(){
				var calculateCFTGroups = function(newGroups){
                	var groups = [];
                	if(newGroups){
                		_.forEach(newGroups, function(ng){
                			_.forEach(ng.groups, function(g){
                				g.groups = calculateCFTGroups(g.newGroups); 
                				g.relatedCustomField = ((g.relatedCustomField && g.relatedCustomField.id)?{id:g.relatedCustomField.id}:undefined);
                				groups.push(g);
                			})
                		})
                	}
                	return groups;
                }
            	var saveModalUpdateThird = function(data){
			        if (data.annexaFormly.form.$valid) {
		                var model = data.annexaFormly.model.modal_body;
						var moduleDoc = 'DOC';
		
		                var document = {
							id:vm.documentComponent.document.id,
		                    type: {id: model.typeSelect}
		                };
						var cf = CustomFieldFactory.getModelValues(model.documentCustomFields);
						document.customFields = [];
		                var documentType = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + document.type.id);
		                if(documentType && cf && cf.length > 0) {
							_.forEach(documentType.customFields, function(docTypeField) {
								var groupField = undefined;
								if(data && data.annexaFormly && data.annexaFormly.fields && data.annexaFormly.fields.length > 0 && data.annexaFormly.fields[0].fieldGroup){
									var docCFFields = $linq(data.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'documentCustomFields'");
									if(docCFFields && docCFFields.fieldGroup){
										groupField = $linq(docCFFields.fieldGroup).firstOrDefault(undefined, "x => x.key == 'cf_"+documentType.id+"_"+docTypeField.customField.id+"'");
									}
								}
                            	var docCustomField = undefined;
                            	if(groupField && groupField.data && groupField.data.customField){
                            		docCustomField = angular.copy(groupField.data.customField);
                            	}else{
                            		docCustomField = angular.copy(docTypeField);	
                            	}
								docCustomField.id = undefined;
								docCustomField.customField = { id: docTypeField.customField.id };
								docCustomField.documentType = { id: documentType.id };
								docCustomField.groups = calculateCFTGroups(docCustomField.newGroups);
								var cfValue = $linq(cf).firstOrDefault(undefined, "x => x.id == 'cf_" + documentType.id  + "_" + docTypeField.customField.id + "'");
								if(cfValue) {
									if(docTypeField.customField.frontendType == 'CHECKBOX'){
		                                var custom_field_selected = $linq(cf).where(function(x){
		                                    if(x.id.startsWith(cfValue.id+"_")){return true}else{return false}}
		                                ).toArray();
		                                if(custom_field_selected && custom_field_selected.length > 0){
		                                    docCustomField.value = '[';
		                                    _.forEach(custom_field_selected, function (item, key) {
		                                        if(item.value) {
		                                            if(docTypeField.customField && docTypeField.customField.listValues && docTypeField.customField.listValues.length > 0) {
		                                                var custom_field_value_selected = $linq(docTypeField.customField.listValues).where(function(x){
		                                                    if(item.id.endsWith("_"+x.value)){return true}else{return false}}
		                                                ).toArray();
		                                                if(custom_field_value_selected && custom_field_value_selected.length > 0){
		                                                    docCustomField.value += ((docCustomField.value == '[') ?custom_field_value_selected[0].value : ',' + custom_field_value_selected[0].value);
		                                                }
		                                            }
		                                        }
		                                    });
		                                    docCustomField.value += ']';
		                                }
		                            }else if(Array.isArray(cfValue.value)) {
		                                if(docTypeField.customField.frontendType == 'MULTIPLESELECT') {
		                                    docCustomField.value = '[';
		
		                                    _.forEach(cfValue.value, function (item) {
		                                        docCustomField.value += ((docCustomField.value == '[') ? item : ',' + item);
		                                    });
		
		                                    docCustomField.value += ']';
		                                } else {
		                                    docCustomField.value = cfValue.value[0];
		                                }
		                            } else {
		                                if(cfValue.value instanceof Date) {
		                                    docCustomField.value = $filter('date')(cfValue.value,'yyyy-MM-ddTHH:mm:ss');
		                                } else {
		                                	if (cfValue && cfValue.value && cfValue.value.toString().includes("\\")) {
		                                		cfValue.value = ((cfValue.value.replaceAll)?cfValue.value.replaceAll('\\','\\\\'):cfValue.value.replace(/\\/g,'\\\\')); 
		                                	}
		                                    docCustomField.value = cfValue.value;
		                                }
		                            }
				                }
								document.customFields.push(docCustomField);
							});
						}
						DccumentsFactory.updateDocumentType(document).then(function(result) {
                			data.close();
	                        $state.reload();
	                    }).catch(function(error) {
		                    data.alerts.push({ msg: $filter('translate')('global.errors.unknownUpdate') });
	                	});
					}
                }
				var openModal = function(){
					var documentTypes = $linq(GlobalDataFactory.documentTypes).where("x => x.id != "+vm.documentComponent.document.type.id +" && x.canChangeToThisType && x.canChangeToThisType == true").toArray();
					var modal = DccumentsFactory.getOpenNewDocumentModal(undefined, undefined, 'DOC', undefined, undefined, vm.documentComponent.document.archiveClassification, undefined, undefined, undefined, undefined, angular.copy(documentTypes));
		    		modal.annexaFormly.fields[0].fieldGroup[1].hideExpression = function(){return true;}
		    		modal.annexaFormly.fields[0].fieldGroup[2].hideExpression = function(){return true;}
		    		modal.annexaFormly.fields[0].fieldGroup[3].hideExpression = function(){return true;}
		    		modal.annexaFormly.fields[0].fieldGroup[4].hideExpression = function(){return true;}
		    		modal.annexaFormly.fields[0].fieldGroup[5].hideExpression = function(){return true;}
		    		modal.annexaFormly.fields[0].fieldGroup[6].hideExpression = function(){return true;}
					var fieldsFromCF = $linq(modal.annexaFormly.fields[0].fieldGroup).where(function(x){if(x && x.key && x.key.startsWith('cf_')){return true}else{return false}}).toArray();
					if(fieldsFromCF){
						_.forEach(fieldsFromCF, function(ffcf){
							ffcf.hideExpression = function ($viewValue, $modelValue, scope) {
		                        if(!ffcf.noEditable) {
									var splitkey = ffcf.key.split('_');
									if(splitkey && splitkey.length == 3){
		                            	return !(splitkey[1] == scope.model.typeSelect);
									}else{
										return false;
									}
		                        }else {
		                            return true;
		                        }
		                    }
						});
					}
 					var modal_body_key = modal.annexaFormly.fields[0].key;
					if(vm.documentComponent && vm.documentComponent.document && vm.documentComponent.document.customFields && vm.documentComponent.document.customFields.length > 0 && vm.documentComponent.document.type && vm.documentComponent.document.type.id){
						if(documentTypes && documentTypes.length > 0){
							_.forEach(vm.documentComponent.document.customFields, function (cfOld) {
								_.forEach(documentTypes, function (type) {
					                _.forEach(type.customFields, function(field) {
										if(cfOld && field && cfOld.customField && field.customField && cfOld.customField.id && cfOld.customField.id == field.customField.id ){
											if(cfOld.value){
												var valueJson = JSON.parse(cfOld.value)
												if(valueJson){
													var cfAux = angular.copy(cfOld);
													if(cfAux && cfAux.customField && cfAux.customField.backendType == 'DATETIME' && valueJson.value){
														cfAux.value = $filter('date')(new Date(valueJson.value), 'dd/MM/yyyy');
													}else{
														cfAux.value = valueJson.value;	
													}
													modal.annexaFormly.model[modal_body_key]['cf_' + type.id + '_' + field.customField.id] = CustomFieldFactory.calculateValueCustomField(cfAux, modal.annexaFormly.model[modal_body_key], ('cf_' + type.id + '_' + field.customField.id));	
												}
											}
 										}
					                });
								});
			            	 });
						}
	                }
		    		AnnexaFormlyFactory.showModal('modalEniDocumentNew', modal, saveModalUpdateThird, false, undefined);                 
				}
				if(vm.documentComponent && vm.documentComponent.document && vm.documentComponent.document.customFields && vm.documentComponent.document.customFields.length > 0){
					DialogsFactory.confirm('global.literals.changeTypeLostInformation', 'global.literals.changeTypeLostInformationBody').then(function (data) {
			        	openModal();
		            }).catch(function (data) {
			        });					
				}else{
					openModal();
				}
			}
			
			vm.printDocumentType = function(model){
                if(vm.languageColumn && vm.documentComponent && vm.documentComponent.document && vm.documentComponent.document.type && vm.documentComponent.document.type[vm.languageColumn]){
					return vm.documentComponent.document.type[vm.languageColumn];
				}else{
					return "";
				}
			}

 			this.$onInit = function() {
				if( vm.documentComponent && vm.documentComponent.document && vm.documentComponent.document.type && vm.documentComponent.document.type.id){
					var documentType = $linq(GlobalDataFactory.documentTypes).firstOrDefault(undefined, "x => x.id == "+vm.documentComponent.document.type.id);
					if(documentType && documentType.canChangeType){
						vm.canChangeDocumentType = true;
					}	
				}
           }
        }]
    })